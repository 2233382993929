body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.inheader

{
  padding-top: 68px !important;
}
body.header-fixed
{
  top:0px !important;
}
body.is_light .homepage
{
  padding-top: 27px !important;

}
.homepage .header.fixedTop.scroll ,.header.fixedTop.scroll{
  top: 49px !important;
}

/* kr css */
#goog-gt-vt{
  display: none !important;
}
.VIpgJd-yAWNEb-L7lbkb.skiptranslate{
  padding: 0 !important;
}
/* body.is_light .homepage,.inheader
{
  padding-top:0px !important;
} */